import { useState, useMemo } from 'react';
import { QuizQuestion, QuizQuestionType } from 'components/NewQuiz/Question/models.d';
import { CurrentAnswer, AnswersList } from 'components/NewQuiz/models';
import formatMultipleAnswer from 'components/NewQuiz/utils';
import { IQuizSearchResults } from 'components/NewQuiz/QuizResults/models.d';

const useQuiz = (questions: QuizQuestion[], salsifyLang: string, xOrigin: string) => {
  const [categoryLink, setCategoryLink] = useState<{
    link?: string | undefined;
    text?: string | undefined;
  }>({});
  const numberOfSteps = questions.length + 1;
  const [searchResults, setSearchResults] = useState<IQuizSearchResults>({
    articles: [],
    products: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowResults, setShowResults] = useState<boolean>(false);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(0);
  const currentQuestion = questions[currentQuestionNumber];
  const [answersList, setAnswersList] = useState<AnswersList>({});
  const [currentAnswer, setCurrentAnswer] = useState<CurrentAnswer[]>([]);
  const isShowBackButton = currentQuestionNumber > 0;
  const isNextButtonDisabled = !currentAnswer.length;

  const currentProgress = useMemo(
    () => Math.round(((currentQuestionNumber + 1) / (questions.length + 1)) * 100),
    [currentQuestionNumber]
  );

  const addAnswer = async () => {
    const isFinalQuestion = currentQuestionNumber === questions.length - 1;
    if (isFinalQuestion) {
      setIsLoading(true);
      const answers: AnswersList = {
        ...answersList,
        [currentQuestion.id]: currentAnswer,
      };
      const tipsTags = answers.tags.map((answer) => answer.value).join(',');
      const textTags = answers.tags.map((answer) => answer.text).join(',');
      const [productResponse, articleResponse] = await Promise.all([
        fetch(`/api/filter-products?limit=4&tipsTags=${tipsTags}&salsifyLang=${salsifyLang}`, {
          method: 'GET',
          headers: { 'x-origin': xOrigin },
        }),
        fetch(`/api/filter-articles?limit=6&tipsTags=${tipsTags}`, {
          method: 'GET',
          headers: { 'x-origin': xOrigin },
        }),
      ]).catch(() => [{ ok: false }, { ok: false }] as const);

      let foundArticles = [];
      let foundProducts = [];
      let isSuccess = true;
      if (productResponse.ok && articleResponse.ok) {
        [foundArticles, foundProducts] = await Promise.all([
          articleResponse.json(),
          productResponse.json(),
        ]).catch(() => [[], []]);
        if (foundProducts.length > 4) {
          foundProducts = foundProducts.slice(0, 4);
        }
        if (foundArticles.length > 0) {
          if (foundProducts.length > 2) {
            foundArticles = foundArticles.slice(0, 1);
            foundProducts = foundProducts.slice(0, 3);
          } else {
            foundArticles = foundArticles.slice(0, foundArticles.length - foundProducts.length);
          }
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'product_finder_2step',
          area: textTags,
        });
      } else {
        isSuccess = false;
      }
      setSearchResults({ articles: foundArticles, products: foundProducts, isSuccess });
      setShowResults(true);
      setIsLoading(false);
    } else {
      // TODO: add callback to send answer question event to API
      setAnswersList((prevState) => ({ ...prevState, [currentQuestion.id]: currentAnswer }));
      setCurrentAnswer(() => []);
      setCurrentQuestionNumber((prevState) => prevState + 1);
    }
  };

  const goBack = () => {
    setCurrentQuestionNumber((prevState) => prevState - 1);
    setCurrentAnswer(() => []);
    // TODO: add callback to send back button press event to API
  };

  const setAnswer = ({ answerId, value, text }: CurrentAnswer) => {
    const { type } = currentQuestion;
    switch (type) {
      case QuizQuestionType.single: {
        if (currentAnswer.length && currentAnswer[0].answerId === answerId) {
          setCurrentAnswer(() => []);
        } else {
          setCurrentAnswer([{ answerId, value, text }]);
        }

        break;
      }
      case QuizQuestionType.carousel: {
        const category = questions[currentQuestionNumber].options.find(
          (option) => option.id === answerId
        );
        questions[currentQuestionNumber + 1].options = category.tags;
        setCategoryLink({
          link: category?.link,
          text: category?.label.text,
        });
        setAnswersList((prevState) => ({
          ...prevState,
          [currentQuestion.id]: [{ answerId, value }],
        }));
        // TODO: add callback to send answer question event to API
        setCurrentQuestionNumber((prevState) => prevState + 1);
        break;
      }
      default: {
        setCurrentAnswer(formatMultipleAnswer(currentAnswer, answerId, value, text));
      }
    }
  };

  const repeatQuiz = () => {
    setAnswersList({});
    setCurrentAnswer([]);
    setCurrentQuestionNumber(0);
    setShowResults(false);
    // TODO: add callback to send try again button press event to API
  };

  return {
    goBack,
    repeatQuiz,
    setAnswer,
    addAnswer,
    isLoading,
    isShowResults,
    isShowBackButton,
    isNextButtonDisabled,
    currentProgress,
    currentQuestion,
    numberOfSteps,
    categoryLink,
    searchResults,
  };
};

export default useQuiz;
