import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IProductResult } from './models';
import './ProductResult.scss';

const ProductResult: FC<IProductResult> = ({ name, size, heroImage, link }) => {
  const handleClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'product_finder_3step',
      product_chosen: `${name}`,
    });
  };

  return (
    <li className="dt-quiz-results__product">
      <Link className="dt-quiz-results__product-link" to={link || '#href'} onClick={handleClick}>
        <div className="dt-image">
          <img className="dt-quiz-results__product-img" src={heroImage} alt={name} />
        </div>
        <strong className="dt-quiz-results__product-title">{`${name} ${size}`}</strong>
      </Link>
    </li>
  );
};

export default ProductResult;
