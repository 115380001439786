import React, { FC } from 'react';

import CardBox from 'components/common/CardBox';
import { IArticleResult } from './models';
import './ArticleResult.scss';

const ArticleResult: FC<IArticleResult> = ({ title, image, link, content }) => {
  const handleClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'product_finder_3step',
      article_chosen: `${title}`,
    });
  };

  return (
    <li className="dt-quiz-results__article" onClick={handleClick}>
      <CardBox
        image={image}
        title={title}
        link={link}
        labelTop={{ type: 'primary', text: content, children: content }}
      />
    </li>
  );
};

export default ArticleResult;
