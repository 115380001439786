import React, { FC } from 'react';

import { IQuizAnswer } from 'components/NewQuiz/Question/QuizAnswer/models';
import './QuizCarouselAnswer.scss';
import UmbracoImage from 'components/common/Image/UmbracoImage';

const QuizCarouselAnswer: FC<IQuizAnswer> = ({ id, image, text, value, onClick }) => {
  const handleClick = () => {
    onClick({ answerId: id, value, text });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'product_finder',
      area: text,
    });
  };

  return (
    // eslint-disable-next-line
    <button type="button" className="dt-quiz-carousel-answer" onClick={handleClick}>
      <div className="dt-quiz-carousel-answer__holder">
        <strong className="dt-quiz-carousel-answer__title">{text}</strong>
        <div className="dt-quiz-carousel-answer__img-holder">
          <UmbracoImage image={image} alt={text} className="dt-quiz-carousel-answer__img" />
        </div>
      </div>
    </button>
  );
};

export default QuizCarouselAnswer;
